export default [

  // { name: '首頁 ', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '全能置產王', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: 0 },
  { name: '富來．境', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: 0 },
  { name: '富來．品', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: 0 },
  { name: '富來．居', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', offset: 0 },
  { name: '富來預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 70 },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, offset: 0 },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, offset: -150 },
]